.parking-layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  &__header {
    position: sticky;
    width: 100%;
    top: 0;
    background-color: white;
    z-index: 1;
  }

  &__main {
    padding-bottom: 32px;
    min-height: 100%;
    flex-grow: 1;
  }

  &__link {
    padding: 4px 8px;
    box-shadow: none !important;

    &--is-active {
      background-color: #d4ece9;
    }
  }

  footer {
    a {
      color: #444444;
    }
  }
}
